import { Button, Input, Switch } from 'antd'
import CountDownCustom from 'components/CountDown/CountDownCustom'
import { DEFAULT_DECIMAL } from 'config/constants'
import { WalletType } from 'config/types/Wallet'
import useToast from 'hooks/useToast'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { toLocaleString } from 'utils'
import { formatAmountOrigin } from 'utils/formatInfoNumbers'
import { Box, Link } from 'widgets'

const Wrapper = styled.div`
  .custom-label {
    padding: 4px 12px;
    border-radius: 2px;
    background: radial-gradient(340.54% 169.54% at 119.18% 126.53%, #000 0%, #060606 48.58%, #1b1b1b 100%);

    color: #f0f6ff;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.4px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 20px;
    }
  }

  .check {
    align-items: center;

    .soon-label {
      padding: 8px 12px;
      border-radius: 12px;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      background: linear-gradient(118deg, rgba(215, 237, 237, 0.16) -47.79%, rgba(204, 235, 235, 0) 100%);
    }

    .switch {
      border: 2px solid #363a3d;
      min-height: 26px;
      background-color: #1b1d21;
    }
  }

  .btn-staking {
    padding: 16px;
    border-radius: 100px;
    box-shadow: 0px 2px 24px 0px rgba(3, 241, 227, 0.12);

    font-size: 16px;
    letter-spacing: 0.5px;
    text-transform: capitalize;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 20px;
      padding: 20px 24px;
    }

    :not(:disabled) {
      background: #03f1e3;
      color: #000;
    }
  }
`

const InputAmount = styled.div`
  border-radius: 100px;
  border: 1px solid #03f1e366;

  .input-amount-left {
    .input {
      border: 0;
      outline: none;
      background-color: transparent;

      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      color: #fff;

      ${({ theme }) => theme.mediaQueries.sm} {
        font-size: 26px;
      }

      ::placeholder {
        color: #868686;
      }
    }
  }
`

export type StakingItemType = {
  time: number
  percentBlock: number
  totalAmountStake: number
  start: number
  finish: number
  plan: number
}

const StakingItem = ({
  data,
  walletBalance,
  onApprove,
  loading,
}: {
  data: any
  walletBalance: WalletType
  onApprove: any
  loading?: boolean
}) => {
  const { toastError } = useToast()
  const [amountStake, setAmountStake] = useState('')

  const handleSuccess = () => {
    setAmountStake('')
  }

  const handleApprove = () => {
    if (Number.isNaN(+amountStake)) {
      toastError('Invalid amount')
      return
    }
    const param = {
      plan: data?.poolId,
      amount: toLocaleString(+amountStake * +DEFAULT_DECIMAL),
    }
    onApprove(param, handleSuccess)
  }

  useEffect(() => {
    if (data?.poolId) {
      setAmountStake('')
    }
  }, [data?.poolId])

  return (
    <Wrapper className="flex flex-col gap-6 py-6">
      <div className="flex sm:flex-row flex-col justify-between sm:items-center gap-4">
        <div className="flex items-center gap-6">
          <p className="text-sm text-[#9B9C9E]">Staking Type</p>
          <div className="custom-label">{data?.isLocked ? 'Locked' : 'Flexible'}</div>
        </div>

        <div className="flex items-center gap-6">
          <p className="text-sm text-[#9B9C9E]">Early Withdraw Fee</p>
          <p className="text-[#E26F20] sm:text-[20px] text-base">10%</p>
        </div>
      </div>

      <div className="flex gap-2">
        <img src="/images/staking/info-circle-solid.png" alt="waring" className="w-4 h-4 relative top-[2px]" />
        <p className="text-sm text-[#E26F20]">
          In case the user Unstakes before the End Period, they will be charged a fee of 10% of the total OPX Staked
        </p>
      </div>

      <div className="flex items-center justify-between gap-3">
        <p className="text-[#CDCECF] md:text-base text-sm">Staking OPX</p>

        <div className="gap-1 flex">
          <p className="text-[#CDCECF] md:text-base text-sm">Balance</p>
          <p className="md:text-base text-sm font-bold">
            {walletBalance?.balance ? formatAmountOrigin(walletBalance?.balance) : 0} OPX
          </p>
        </div>
      </div>

      <InputAmount className="flex w-full justify-between gap-6 p-4 bg-black">
        <div className="input-amount-left flex items-center flex-1 gap-2">
          <img src="/images/logo.png" alt="logo" className="w-[36px] h-[36px]" />
          <Input
            onChange={(e) => setAmountStake(e.target.value)}
            value={amountStake}
            className="input"
            placeholder="0"
            disabled={!walletBalance?.balance}
          />
          <p className="sm:flex hidden text-[24px]">OPX</p>
        </div>

        <Box
          className="py-3 px-4 bg-white rounded-[100px] text-black cursor-pointer hover:opacity-80 md:text-base text-xs"
          onClick={() => setAmountStake(walletBalance?.balance?.toString())}
        >
          MAX
        </Box>
      </InputAmount>

      <div className="check flex gap-3">
        <Switch disabled className="switch" />
        <p className="sm:text-[24px] text-[18px]">NFT Boost</p>
        <div className="soon-label text-xs">Soon</div>
      </div>

      <Button
        className="btn-staking"
        onClick={handleApprove}
        disabled={
          !walletBalance?.balance ||
          !amountStake ||
          !(new Date().getTime() > +data?.startBlock * 1000 && new Date().getTime() < +data?.endBlock * 1000)
        }
        loading={loading}
      >
        {!walletBalance?.balance ? (
          'Influence balance'
        ) : new Date().getTime() > +data?.startBlock * 1000 && new Date().getTime() < +data?.endBlock * 1000 ? (
          `Staking OPX`
        ) : new Date().getTime() < +data?.startBlock * 1000 ? (
          <CountDownCustom remains={+data?.startBlock * 1000} />
        ) : (
          'Ended'
        )}
      </Button>

      <div className="flex items-center justify-between gap-3 w-fit mx-auto">
        <p>Want to buy more? </p>
        <Link href="/" external className="text-sm text-[#03F1E3]">
          Buy OPX
        </Link>
      </div>
    </Wrapper>
  )
}

export default StakingItem
